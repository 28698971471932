const SupportUs = () => {
  return (
    <div className="about">
      <h1>Want to support this website?</h1>
      <br />
      <p>
        The continued development and hosting of this site, as well as continued
        work on expanding the scale and accuracy of our dataset would be greatly
        helped by donations from users who find our work useful. Please consider
        donating via one of the methods below:
      </p>
      <br />
      <ul>
        <li>
          <a
            href="https://www.buymeacoffee.com/leatherapr9"
            target="_blank"
            rel="noreferrer"
          >
            Buy Me a Coffee
          </a>
        </li>
        <li>
          <a
            href="https://www.subscribestar.com/leather-apron-club"
            target="blank"
            rel="noreferrer"
          >
            SubscribeStar - For recurring payments
          </a>
        </li>
      </ul>

      <br />
      <p>
        If you would like to support Social Sentinel in a way not listed above,
        contact: leatherapronchan@gmail.com
      </p>
    </div>
  );
};

export default SupportUs;
