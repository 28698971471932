import { useState, useEffect } from "react";
import { getIndividualUnderscored } from "../../helpers";
import { useNavigate } from "react-router-dom";
import axios from "../../app/api/axios";

const SUMMARY_URL = "/individualSummary/";

const IndividualSummary = (props) => {
  const [individual, setIndividual] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(getIndividualUnderscored(props.name));
  };

  useEffect(() => {
    const getIndividual = async () => {
      const { data } = await axios.get(SUMMARY_URL + props.name);

      setIndividual(data[0]);
    };
    getIndividual();
  }, [props.name]);

  return (
    <div className="individual-summary">
      {!individual && (
        <img
          src="https://via.placeholder.com/300"
          className="individual-summary-image"
          alt="individual"
        />
      )}
      {individual && (
        <div onClick={handleClick}>
          <img
            src={individual.individual_image_url}
            className="individual-summary-image"
            alt="individual"
          />
          <h2>{props.name}</h2>
          {individual.ethnicity && (
            <p>
              <b>ETHNICITY:</b> {individual.ethnicity}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default IndividualSummary;
