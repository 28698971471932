import React from "react";
import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Modal from "react-modal";
import { LoginButton } from "@telegram-auth/react";

import { useDispatch } from "react-redux";
import { setCredentials, setUserInfo } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import { useSendLogoutMutation } from "../../features/auth/authApiSlice";
import { useRefreshMutation } from "../../features/auth/authApiSlice";
import usePersist from "../../hooks/usePersist";
import styled from "styled-components";
import axios from "../../app/api/axios";
import LogoutIcon from "@mui/icons-material/Logout";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { jwtDecode } from "jwt-decode";

import { useSelector } from "react-redux";
import {
  selectCurrentToken,
  selectCurrentUserInfo,
} from "../../features/auth/authSlice";
import { Input } from "@mui/material";

import "./AuthUserWidget.css";
import {
  instagramRegex,
  telegramRegex,
  twitterRegex,
  youTubeRegex,
} from "../../helpers";

const ModalHeader = styled.h1({
  fontSize: "1.7rem",
  fontWeight: "bold",
  color: "white",
});

const AuthUserWidget = () => {
  const [userIsAuthed, setUserIsAuthed] = useState(false);
  const [userLink, setUserLink] = useState("");
  const [linkError, setLinkError] = useState(false);

  const [sendLogout] = useSendLogoutMutation();

  const dispatch = useDispatch();

  const [login] = useLoginMutation();

  const [persist] = usePersist();
  const token = useSelector(selectCurrentToken); // our current access token
  const userInfo = useSelector(selectCurrentUserInfo); // our current access token
  // const userInfo = null;
  const effectRan = useRef(false);

  const [, setTrueSuccess] = useState(false);

  const [refresh, { isSuccess }] = useRefreshMutation();

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      // React 18 Strict Mode

      const verifyRefreshToken = async () => {
        try {
          //const response =
          await refresh();
          //const { accessToken } = response.data
          setTrueSuccess(true);
        } catch (err) {
          console.error(err);
        }
      };

      if (!token && persist) verifyRefreshToken();
    }

    return () => (effectRan.current = true);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      console.log("Refresh success");
      setUserIsAuthed(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      console.log("user changed link");
      setLinkError(false);
    }
  }, [userLink, isSuccess]); // TODO: added isSuccess as an untest change to eliminate warning. Verify this doesn't cause problems.

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const doLogin = async (authData) => {
    try {
      const res = await login(authData);
      const { accessToken } = res.data;

      const payload = jwtDecode(accessToken).payload;

      dispatch(setCredentials({ accessToken }));
      dispatch(setUserInfo({ payload }));
      setUserIsAuthed(true);

      closeModal();
    } catch (err) {
      if (!err?.response) {
        console.log("No server response");
      } else if (err.response.status === 400) {
        console.log("Bad Request");
      } else if (err.response.status === 401) {
        console.log("Internal server error");
      } else {
        console.log("Login Failed");
      }
    }
  };

  const doLogout = async () => {
    try {
      await sendLogout();
      setUserIsAuthed(false);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const validateUserLink = (link) => {
    console.log("Validating link", link);
    const twitterRegExp = new RegExp(twitterRegex);
    const instagramRegExp = new RegExp(instagramRegex);
    const telegramRegExp = new RegExp(telegramRegex);
    const youtubeRegExp = new RegExp(youTubeRegex);

    if (
      !(
        twitterRegExp.test(link) ||
        instagramRegExp.test(link) ||
        telegramRegExp.test(link) ||
        youtubeRegExp.test(link)
      )
    ) {
      setLinkError(true);
      return false;
    }
    return true;
  };

  const handleUpdateLink = async () => {
    toast.dismiss();

    if (!validateUserLink(userLink)) {
      toast.error("Invalid Link", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });

      return;
    }

    const { data } = await axios.post("/auth/updatePersonalLink", {
      link: userLink,
    });

    toast.success("Your new link will be added to your contributed pages.", {
      toastId: "linkToast",
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });

    console.log(data);
  };

  const handleAnonymousCheckboxChange = async () => {
    toast.dismiss();

    if (userInfo === null || userInfo === undefined) return;

    let val = userInfo.user_is_anonymous;

    if (val === 1) val = 0;
    else val = 1;

    let newUserInfo = { ...userInfo };
    newUserInfo.user_is_anonymous = val;

    dispatch(setUserInfo({ payload: newUserInfo }));

    const { data } = await axios.get("/auth/changeAnonStatus/" + val);

    // TODO: what to do if we get no response
    let message =
      val === 1 ? "Anonymous mode enabled" : "Anonymous mode disabled";

    toast.success(message, {
      // toastId: "anonToast",
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    console.log(data);
  };

  return (
    <div>
      {userIsAuthed && (
        <img
          className="profile-image"
          src="https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg"
          alt="profile"
          width="50"
          height="50"
          onClick={openModal}
        />
      )}
      {!userIsAuthed && (
        <Button variant="contained" onClick={openModal}>
          Sign in
        </Button>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        className="modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {userIsAuthed && (
          <div>
            <img
              className="modal-profile-image"
              src={
                "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg"
              }
              alt="profile"
              width="55"
              height="55"
            />
            <ModalHeader>
              {userInfo !== null && userInfo !== undefined
                ? userInfo.username
                : ""}
            </ModalHeader>
            <div className="user-points-section">
              <div className="pointsText">
                {userInfo !== null && userInfo !== undefined
                  ? userInfo.points
                  : 0}
              </div>
              <span className="goldMedal"></span>
              <div className="goldText">
                {userInfo !== null && userInfo !== undefined
                  ? userInfo.gold
                  : 0}
              </div>
              <span className="silverMedal"></span>
              <div className="silverText">
                {userInfo !== null && userInfo !== undefined
                  ? userInfo.silver
                  : 0}
              </div>
              <span className="bronzeMedal"></span>
              <div className="bronzeText">
                {userInfo !== null && userInfo !== undefined
                  ? userInfo.bronze
                  : 0}
              </div>
            </div>
            <div className="anonymous-section">
              <input
                // checked={userInfo.user_is_anonymous}
                checked={userInfo !== null && userInfo.user_is_anonymous}
                onChange={handleAnonymousCheckboxChange}
                type="checkbox"
                id="anonymous"
                name="anonymous"
              />
              <label className="anonymous-label" htmlFor="anonymous">
                Make my contributions anonymous
              </label>
            </div>
            <div className="update-link-section">
              <Input
                error={linkError}
                className="link-input"
                label="Site Link"
                variant="filled"
                value={userInfo !== null && userInfo.personal_link}
                helperText="Insert a valid link"
                onChange={(e) => {
                  setUserLink(e.target.value);
                }}
              />
              <Button
                className="update-link-button"
                size="small"
                variant="contained"
                onClick={handleUpdateLink}
              >
                Update Personal Link
              </Button>
              <br />
              <p className="contributions-explanation">
                *Insert a valid link your Twitter, Telegram, YouTube, or
                Instagram page. When you contribute a page to Social Sentinel,
                users will be able to see you contributed and visit the link you
                put here. If you want your contributions to remain anonymous,
                check the "Anonymous Contributions" box above.
              </p>
            </div>
            <Button
              variant="contained"
              endIcon={<LogoutIcon />}
              onClick={doLogout}
            >
              Logout
            </Button>
          </div>
        )}
        {!userIsAuthed && (
          <div>
            <ModalHeader>Sign in</ModalHeader>
            <LoginButton
              botUsername="socialsentinel_bot"
              lang="en"
              onAuthCallback={(data) => {
                console.log(data);
                doLogin(data); // sends the returned TG auth data to backend to verify + create session
              }}
              buttonSize="large" // "large" | "medium" | "small"
              cornerRadius={5} // 0 - 20
              showAvatar={true} // true | false
            />
            <p>*Personal information will not be sent to Social Sentinel.</p>
            <button onClick={closeModal}>close</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AuthUserWidget;
