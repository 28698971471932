import { useState, useRef } from "react";
import axios from "../../app/api/axios";
import { Button, Input } from "@mui/material";
import {
  countryOptions,
  almaMaterOptions,
  completedStudies,
  ethnicityOptions,
  youTubeRegex,
  wikipediaRegex,
  twitterRegex,
  instagramRegex,
  tiktokRegex,
} from "../../helpers";
import Select from "react-select-virtualized";
import TextareaAutosize from "react-textarea-autosize";

import {
  selectCurrentToken,
  selectCurrentUserInfo,
} from "../../features/auth/authSlice";
import { useSelector } from "react-redux";

import Tooltip from "@mui/material/Tooltip";

import InfoIcon from "@mui/icons-material/Info";
import SubmissionFormSearchBar from "../SubmissionFormSearchBar/SubmissionFormSearchBar";
import PersonalRelationsBuidler from "../PersonalRelationsBuidler/PersonalRelationsBuidler";
import SourcesBuilder from "../SourcesBuilder/SourcesBuilder";

const NATIONALITY_MAX = 2;
const ALMA_MATER_MAX = 3;

const IndividualSubmissionForm = () => {
  const [nationality, setNationality] = useState([]);
  const [almaMater, setAlmaMater] = useState([]);
  const [studies, setStudies] = useState([]);
  const [image, setImage] = useState(null);

  const token = useSelector(selectCurrentToken); // our current access token
  const userInfo = useSelector(selectCurrentUserInfo);

  const spouseRef = useRef();
  const relationsInputRef = useRef();
  const sourceBuilderRef = useRef();

  // let studiesOptions = []
  // for (let i = 0; i < completedStudies.length; i++) {
  //   studiesOptions.push({ value: completedStudies[i], label: completedStudies[i] })
  // }

  const submitForm = async (e) => {
    e.preventDefault();

    console.log(spouseRef.current.getValues());

    const formData = new FormData(e.target);
    const payload = Object.fromEntries(formData);

    if (!image) {
      alert("Please upload an image of the individual.");
      return;
    }

    const fd = new FormData();
    console.log("dob", new Date(payload.dob).toISOString().split("T")[0]);
    fd.append("image", image);
    fd.append("name", payload.name); // Always send original name since it's the PK
    fd.append("nativeName", payload.nativeName);
    // fd.append("aliases", aliases.map((n) => n.value).join("|"));
    fd.append("biography", payload.biography);
    fd.append("ethnicity", payload.ethnicity);
    fd.append("nationality", nationality.map((n) => n.value).join("|"));
    fd.append("almaMater", almaMater.map((n) => n.value).join("|"));
    fd.append("spouse", spouseRef.current.getValues());
    fd.append("studies", studies.map((n) => n.value).join("|"));
    // fd.append("sources", sources.map((n) => n.value).join("|"));
    fd.append("ethnicityNotes", payload.ethnicityNotes);
    fd.append("wikipedia", payload.wikipedia);
    fd.append("youtube", payload.youtube);
    fd.append("instagram", payload.instagram);
    fd.append("tiktok", payload.tiktok);
    fd.append("twitter", payload.twitter);
    fd.append("personalSite", payload.personalSite);
    fd.append(
      "dob",
      payload.dob === ""
        ? null
        : new Date(payload.dob).toISOString().split("T")[0]
    );
    console.log(payload.dateOfDeath);
    fd.append(
      "dateOfDeath",
      payload.dateOfDeath === ""
        ? null
        : new Date(payload.dateOfDeath).toISOString().split("T")[0]
    );
    fd.append("isNewSubmission", 1);

    if (token) {
      const axiosConfig = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };

      if (userInfo) {
        console.log(userInfo);
        fd.append("contributedBy", userInfo.telegram_id);
      }

      const { data } = await axios.post("/submitIndividual", fd, axiosConfig);

      console.log(data);
    } else {
      console.log("no token detected. Authenticate.");
    }
    // const { data } = await axios.post("/protected/submitIndividual", fd);

    // const { data } = await axios
    //   .post("/submitIndividual", fd, {
    //     onUploadProgress: (progressEvent) => {
    //       console.log(
    //         "Upload Progress: " +
    //           Math.round((progressEvent.loaded / progressEvent.total) * 100) +
    //           "%"
    //       );
    //     },
    //     headers: {
    //       "Custom-Header": "value",
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const onChangeNationality = (value) => {
    let temp;
    if (Array.isArray(value)) {
      temp = value;
    } else {
      temp = [...nationality, value];
    }

    if (nationality.length >= NATIONALITY_MAX) {
      temp = temp.slice(1);
    }
    setNationality(temp);
  };

  const onChangeAlmaMater = (value) => {
    let temp;
    if (Array.isArray(value)) {
      temp = value;
    } else {
      temp = [...almaMater, value];
    }

    if (almaMater.length >= ALMA_MATER_MAX) {
      temp = temp.slice(1);
    }
    setAlmaMater(temp);
  };

  const onChangeStudies = (value) => {
    if (Array.isArray(value)) {
      setStudies(value);
    } else {
      setStudies([...studies, value]);
    }
  };

  return (
    <div className="main-section">
      <form
        className="individuals-page"
        enctype="multipart/form-data"
        onSubmit={submitForm}
      >
        <div className="individual-details">
          <div>
            <Tooltip
              className="tooltip"
              placement="right-start"
              title="The name this person most commonly goes by."
            >
              <InfoIcon />
            </Tooltip>
            <Input
              type="text"
              placeholder="Enter Individual's Name"
              className="individual-submission-name-input"
              required
              style={{
                fontSize: "175%",
                fontWeight: "bold",
                fontFamily: "Quicksand",
              }}
              name="name"
            />
          </div>
          <hr />
          <div className="individual-detail-section">
            <div className="individual-submission-input-section">
              <label>
                <Tooltip
                  className="tooltip"
                  placement="right-start"
                  title="This individual's birth name (not maiden name). Used if they go by a false name, or stage name (e.g. Lady Gaga = stage name, Caryn Johnson (AKA Whoopi Goldberg) = false name)."
                >
                  <InfoIcon />
                </Tooltip>
                <b>NATIVE NAME:</b>
                <Input type="text" name="nativeName" />
              </label>
            </div>

            <div className="individual-submission-input-section">
              <label>
                <Tooltip
                  className="tooltip"
                  placement="right-start"
                  title="Additional names this individual is known by e.g. nicknames, names they no longer go by, screen names, etc."
                >
                  <InfoIcon />
                </Tooltip>
                <b>ALSO KNOWN AS: </b>
                <Input type="text" name="aliases" />
              </label>
            </div>

            <div>
              <b>BIOGRAPHY:</b>
              <br />
              <TextareaAutosize
                className="biography-textarea"
                name="biography"
                placeholder="Write a brief bio here."
                minRows={4}
                maxRows={10}
              />
            </div>

            <div>
              <b>NATIONALITY (MAX 2):</b>
              <Select
                maxHeight={300}
                isMulti
                onChange={onChangeNationality}
                value={nationality}
                options={countryOptions}
                name="nationality"
              />
            </div>

            <div>
              <b>ALMA MATER (MAX 3):</b>{" "}
              <Select
                maxHeight={300}
                isMulti
                onChange={onChangeAlmaMater}
                value={almaMater}
                options={almaMaterOptions}
                name="almaMater"
              />
            </div>

            <div>
              <Tooltip
                className="tooltip"
                placement="right-start"
                title="If this individual's spouse is already in the data set, select them here. Otherwise, simply type in their name."
              >
                <InfoIcon />
              </Tooltip>
              <b>SPOUSE: </b>
              <SubmissionFormSearchBar ref={spouseRef} isIndividual={true} />
            </div>
          </div>

          <div className="individual-detail-section">
            <div className="tooltip-div">
              <h1>Studies Featured In:</h1>
              <Tooltip
                className="tooltip"
                placement="right-start"
                title="If this individual is a relevant part of an existing study, select it here and they will be added to the study's page."
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <hr />
            <div className="individual-detail-section">
              <Select
                maxHeight={300}
                onChange={onChangeStudies}
                value={studies}
                isMulti
                options={completedStudies}
                name="studies"
              />
            </div>
          </div>

          <div className="individual-detail-section">
            <h1>Relations and Associates:</h1>
            <hr />
            <div className="individual-detail-section">
              <PersonalRelationsBuidler
                name="relations"
                ref={relationsInputRef}
              />
            </div>
          </div>
          <Button
            size="small"
            variant="contained"
            type="submit"
            className="individual-submit-button"
          >
            SUBMIT
          </Button>
        </div>

        <div className="spacer"></div>

        <div className="individual-image-container">
          <img
            className="individual-image"
            src={image ? URL.createObjectURL(image) : ""}
            alt=""
          />
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg, image/webp"
            name="image"
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
          />
          <div className="individual-image-details individual-detail-section">
            <Select
              required
              maxHeight={300}
              options={ethnicityOptions}
              name="ethnicity"
            />
            <p>{/* <b>*</b> {individual.ethnicity_notes} */}</p>
            <div className="sources-div">
              Sources:
              <SourcesBuilder ref={sourceBuilderRef} />
            </div>
            <div className="individual-detail-section">
              Wikipedia Link:
              <Input
                type="text"
                name="wikipedia"
                inputProps={{
                  pattern: wikipediaRegex,
                }}
              />
            </div>
            <div className="individual-detail-section">
              YouTube (or other video) Link:
              <Input
                type="text"
                name="youtube"
                inputProps={{
                  pattern: youTubeRegex,
                }}
              />
            </div>
            <div className="individual-detail-section">
              X (Twitter) Link:
              <Input
                type="text"
                name="twitter"
                inputProps={{
                  pattern: twitterRegex,
                }}
              />
            </div>
            <div className="individual-detail-section">
              Instagram Link:
              <Input
                type="text"
                name="instagram"
                inputProps={{
                  pattern: instagramRegex,
                }}
              />
            </div>
            <div className="individual-detail-section">
              TikTok Link:
              <Input
                type="text"
                name="tiktok"
                inputProps={{
                  pattern: tiktokRegex,
                }}
              />
            </div>
            <div className="individual-detail-section">
              Personal Website Link:
              <Input type="text" name="personalSite" />
            </div>
            {/* {(individual.wikipedia ||
              individual.youtube ||
              individual.twitter ||
              individual.more_info ||
              individual.instagram ||
              individual.tiktok) && (
              <div className="social-media-links">
                {individual.wikipedia && (
                  <a
                    href={individual.wikipedia}
                    className="social-media-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tooltip
                      className="tooltip"
                      placement="top"
                      title={individual.name + "'s Wikipedia Entry"}
                    >
                      <FontAwesomeIcon icon={faWikipediaW} />
                    </Tooltip>
                  </a>
                )}
                {individual.youtube && (
                  <a
                    href={individual.youtube}
                    className="social-media-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tooltip
                      className="tooltip"
                      placement="top"
                      title={individual.name + "'s YouTube Channel"}
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </Tooltip>
                  </a>
                )}
                {individual.twitter && (
                  <a
                    href={individual.twitter}
                    className="social-media-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tooltip
                      className="tooltip"
                      placement="top"
                      title={individual.name + "'s X Account"}
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </Tooltip>
                  </a>
                )}
                {individual.instagram && (
                  <a
                    href={individual.instagram}
                    className="social-media-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tooltip
                      className="tooltip"
                      placement="top"
                      title={individual.name + "'s Instagram Account"}
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </Tooltip>
                  </a>
                )}
                {individual.tiktok && (
                  <a
                    href={individual.tiktok}
                    className="social-media-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tooltip
                      className="tooltip"
                      placement="top"
                      title={individual.name + "'s TikTok Account"}
                    >
                      <FontAwesomeIcon icon={faTiktok} />
                    </Tooltip>
                  </a>
                )}
                {individual.more_info && (
                  <a
                    href={individual.more_info}
                    className="social-media-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tooltip
                      className="tooltip"
                      placement="top"
                      title={individual.name + "'s Website"}
                    >
                      <FontAwesomeIcon icon={faGlobe} />
                    </Tooltip>
                  </a>
                )}
              </div>
            )}
            <div className="contributed-by-section">
              {individual.contributed_by &&
              individual.contributed_by &&
              individual.contributed_by_link ? (
                <p>
                  Contributed By:{" "}
                  <a
                    href={individual.contributed_by_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {individual.contributed_by}
                  </a>
                </p>
              ) : (
                <p>Contributed By: {individual.contributed_by}</p>
              )}
            </div> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default IndividualSubmissionForm;
