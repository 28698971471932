import { FeaturedIndividuals, FeaturedOrganizations } from "./components";

const Home = () => {

  return (
    <div className="home">
      <FeaturedIndividuals />
      <FeaturedOrganizations />
    </div>
  );
}
 
export default Home;
