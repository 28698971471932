import React from "react";
import { getIndividualUnderscored } from "../../helpers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";

const RANDOM_INDIVIDUAL_URL = "/randomIndividual/";

const RandomIndividualButton = () => {
  const navigate = useNavigate();

  const getName = async () => {
    const { data } = await axios.get(RANDOM_INDIVIDUAL_URL);
    navigate(getIndividualUnderscored(data[0].name));
  };

  return (
    <Button className="" size="small" variant="contained" onClick={getName}>
      Random Individual
    </Button>
  );
};

export default RandomIndividualButton;
