import React, {
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import axios from "../../app/api/axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import "./SubmissionFormSearchBar.css";

const INDIVIDUAL_SEARCH_URL = "/searchIndividualOnly/";
const ORGANIZATION_SEARCH_URL = "/searchOrganizationOnly/";

const SubmissionFormSearchBar = forwardRef(
  ({ isIndividual, defaultValue }, ref) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [query, setQuery] = useState("");
    // const loading = open && options.length === 0 && query.length > 2;
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
      getValues() {
        return query;
      },
    }));

    const getIndividuals = (e) => {
      if (e === null) return; // When setting defaultValue, e is null. This avoids the error, and stops an unnecessary function call.
      setLoading(true);
      const searchTerm = e.target.value;
      if (searchTerm === defaultValue.name) return;
      setQuery(searchTerm);

      if (
        searchTerm === undefined ||
        searchTerm === "" ||
        searchTerm.length < 3
      ) {
        setLoading(false);
        return;
      }
      axios
        .get(INDIVIDUAL_SEARCH_URL + searchTerm)
        .then((data) => {
          setLoading(false);
          setOptions(data.data);
        })
        .catch((err) => {
          setLoading(false);

          if (err.response && err.response.status === 404) {
            setOptions(null);
          }
        });
    };

    const getOrganizations = (e) => {
      setLoading(true);
      const searchTerm = e.target.value;

      if (
        searchTerm === undefined ||
        searchTerm === "" ||
        searchTerm.length < 3
      ) {
        setLoading(false);
        return;
      }
      axios
        .get(ORGANIZATION_SEARCH_URL + searchTerm)
        .then((data) => {
          setLoading(false);
          setOptions(data.data);
        })
        .catch((err) => {
          setLoading(false);

          if (err.response && err.response.status === 404) {
            setOptions(null);
          }
        });
    };

    function debounce(callback, wait) {
      let timerId;
      return function (...args) {
        const context = this;
        if (timerId) clearTimeout(timerId);
        timerId = setTimeout(() => {
          timerId = null;
          callback.apply(context, args);
        }, wait);
      };
    }

    const debouncedResults = useMemo(() => {
      const getFunction = isIndividual ? getIndividuals : getOrganizations;
      return debounce(getFunction, 300);
    }, []);

    const handleOnSelect = (item) => {
      console.log(item);
    };

    return (
      <Autocomplete
        className="submission-form-search-bar"
        variant="standard"
        size="small"
        disableClearable
        sx={{ "& fieldset": { borderRadius: 33 } }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        // This ensures the component doesn't filter out options that don't match the input.
        // This is the default behavior, but as we'll search on native name in the query and return
        // just 'name', the component would filter these out.
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.name}
        defaultValue={defaultValue}
        options={options}
        loading={loading}
        openOnFocus={false}
        onInputChange={debouncedResults}
        freeSolo // This allows the user to enter a value that is not in the list
        onChange={(_e, value) => {
          if (value) {
            handleOnSelect(value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onMouseDownCapture={(e) => e.stopPropagation()}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    );
  }
);

export default SubmissionFormSearchBar;
