import { Link } from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar";
import AuthUserWidget from "../AuthUserWidget/AuthUserWidget";

const Navbar = () => {

  return (
    <nav className="navbar">
      <a href="/">
        <h1>Social Sentinel--ALPHA</h1>
      </a>
      <SearchBar />
      <div className="links">
        <Link to="/about">About</Link>
        <Link to="/support">Support Us</Link>
        <Link to="/memegenerator">Meme Generator</Link>
        <AuthUserWidget />

      </div>
    </nav>
  );
};

export default Navbar;
