const About = () => {
  return (
    <div className="about">
      <h2>About</h2>
      <hr />
      <br />
      <p>
        Social Sentinel was made in collaboration by Alex from{" "}
        <a
          href="https://bio.link/leatherapronclub"
          target="_blank"
          rel="noreferrer"
        >
          Leather Apron Club
        </a>{" "}
        and{" "}
        <a
          href="https://twitter.com/AntiWhiteWatch1"
          target="_blank"
          rel="noreferrer"
        >
          Anti White Watch
        </a>
        . Data gathering and coding were handled by the above two.
      </p>
      <br />

      <h2>Frequently Asked Questions</h2>
      <hr />
      <br />
      <ul>
        <li>
          <p>
            <b>What is the point of this website?</b>
          </p>
          <br />
          <p>
            We made this website in order to discuss, in a rational manner, the
            realities of the power which the Jewish/Israeli/Zionist lobby has in
            key positions of influence in the West. We believe this power is
            harmful to the countries in which it is present, just as any power
            which operates without regard to the interests of the country's
            citizens naturally is.
          </p>
          <br />
          <p>
            Unfortunately, while we believe this is very much a legitimate
            political issue which deserves our collective attention, the topic
            is often considered verboten. First, because it is inconvenient for
            many in positions of power to discuss, Jews and non-Jews alike.
            Second, because much of the public discourse that does occur around
            this topic is of a low quality, often propagated by people who make
            wild claims which paint us all as unreliable. Anyone attempting to
            discuss the topic is invariably labeled ‘extreme’, ‘fringe’ or some
            sort of bigot (racist, anti-semite etc) and is promptly ignored.
          </p>
          <br />
          <p>
            This site therefore was created to show people who have hitherto
            been afraid to touch this subject that there is indeed an undeniably
            disproportionate level of Jewish influence in the West, where
            exactly that influence exists, and hopefully, it will convince these
            people to reflect on these realities and come to the conclusion that
            this is a legitimate issue they should concern themselves with, one
            which they should not be afraid to discuss rationally.
          </p>
        </li>

        <br />

        <li>
          <p>
            <b>Who counts as Jewish?</b>
          </p>
          <br />
          <p>
            The first objection someone may make if they are trying to discredit
            this site is to say that our figures for Jewish over-representation
            must only be so high because we count absolutely everybody,
            including people who are only a small part Jewish, as Jewish. To
            this end, we want to make clear that this is not the case at all,
            and that in fact, we are extremely conservative in who we decide to
            label as Jewish. They must meet the following criteria:
          </p>
          <br />
          <ol>
            <li>
              Have at least 1 full blooded Jewish parent (50% ethnically Jewish
              or more, with a rarely used exception for someone who is only ¼
              Jewish, yet identifies publicly as Jewish).
            </li>
            <li>Have publicly identified themselves as Jewish.</li>
            <li>
              Converts to Judaism who are not ethnically Jewish as per the above
              rules do not count.
            </li>
          </ol>
          <br />
          <p>
            Rational: Online discussion of legitimate concerns surrounding
            Jewish power in Western countries is often quickly muddied by bad
            actors who make baseless claims that certain public figures are
            Jewish when they simply are not. This is usually done by either
            unhinged individuals who have no idea what they’re talking about, or
            by political actors intentionally muddying the waters.
          </p>
          <br />
          <p>
            As one of the goals of this website is to shift the politics of the
            West to permit rational discussion of the reality of Jewish power,
            we want all of our data and the conclusions drawn from them to be
            beyond reproach. We could have easily justified counting all
            individuals with at least one Jewish grandparent as Jewish, thereby
            making our figures even more sensational. This is the standard used
            by Israel for its{" "}
            <a
              href="https://en.wikipedia.org/wiki/Law_of_Return"
              target="_blank"
              rel="noreferrer"
            >
              “Law of Return”
            </a>{" "}
            after all. But as it is, know that we have indeed decided to be
            stricter than even Israel in determining who is Jewish.
          </p>
          <br />
          <p>
            As to point number 2 – we only consider sources of information made
            public by the individuals themselves. If someone has never publicly
            mentioned that they are Jewish, and their race isn’t otherwise
            evident (e.g. people who are of obviously African descent), they are
            marked as White. This means that in all likelihood, many Jews are
            mislabeled as White. This is acceptable to us because we always
            prefer for the sake of data accuracy to mislabel a Jew as White
            rather than the other way around. Bad actors would seek out and
            amplify any such mistakes as a means to discredit the entirety of
            our website, so we have very strong motivation to avoid them.
          </p>
          <br />
          <p>
            As to point number 3 – This website aims primarily to study ethnic
            representation, so religious affiliation does not matter, although
            it will be noted on that individual's page.
          </p>
          <br />
          <p>
            Even with these strict standards, which all but guarantee that all
            of our figures for Jewish over-representation are{" "}
            <b>under-estimates</b>, we can still point to hundreds of instances
            in media, entertainment, pornography, banking, hedge fund management
            and many more where ethnic Jews are overrepresented by a factor of{" "}
            <b>20</b> or more.
          </p>
        </li>

        <br />

        <li>
          <p>
            <b>How is the data gathered?</b>
          </p>
          <br />
          <p>
            As the number of names in our database is massive, and growing
            larger every day, we can not rely solely on manual research. We
            therefore use AI (Large Language Models) and data scraping
            techniques to handle the bulk of the research.
          </p>
          <br />
          <p>
            By supplying only a list of names, our system is able to pull from a
            multitude of online sources (Wikipedia, Twitter, Google Searches and
            more) to gather quality information on any public individual.
          </p>
        </li>

        <br />

        <li>
          <p>
            <b>
              Given that your data is gathered by AI, how do you guarantee data
              accuracy?
            </b>
          </p>
          <br />

          <p>
            Since AI is utilized to gather our data, and Large Language Models
            are famous for ‘hallucinating’ or making simple mistakes, data
            accuracy is a top concern of ours.
          </p>
          <br />

          <p>
            To alleviate these issues, the AI is only given documents which our
            other, non-AI systems have gathered. Instead of simply asking the AI
            a question: “What is Ben Shapiro's ethnicity?”, to which it could
            easily provide a false answer based on it’s own existing knowledge
            bases, we make sure to only feed it high quality data sources which
            it then references to answer our questions. (Technical details:
            Scraping Google results, building up vector databases, feeding these
            to the AI. Preferred to use known sources like Wikipedia, or the
            individual’s own Social Media accounts)
          </p>
          <br />

          <p>
            In order to test the accuracy of the AI, during initial development
            we fed it the thousands of names we had already researched manually
            during the creation of our previous representation series videos -
            <br />
            <br />
            <ul>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=RZFApIBGQAg"
                  target="_blank"
                  rel="noreferrer"
                >
                  Joe Rogan's podcast guests
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=02oKSUlGc0c"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lex Fridman's podcast guests
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=h5j7h_cy0uI"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sam Harris' podcast guests
                </a>
              </li>
            </ul>
            <br />
            We then compared the AI output to the results we had found manually,
            and were able to adjust the program until the results agreed with
            our manually gathered results in 99.5% of cases, with the few
            disagreements almost always being tougher edge cases.
          </p>
          <br />

          <p>
            Even after the AI does the bulk of the work, we always go back to
            check the results it produced, so every piece of data on this
            website has been manually reviewed.
          </p>
          <br />

          <p>
            This all being said, there still are undoubtedly issues with the
            data, which we will always be working to correct. If anyone finds
            any issues, please email leatherapronchan@gmail.com with details,
            and the problem will quickly be fixed.
          </p>
        </li>
        <br />

        <li>
          <p>
            <b>How can I contribute to Social Sentinel?</b>
          </p>
          <br />
          <p>
            Notice a particular individual or organization missing from our
            dataset? Want to correct an error? The goal of Social Sentinel is to
            document every single public figure and organization as accurately
            as possible. Given the size of this task, we will of course be
            accepting outside help.
          </p>
          <br />
          <p>
            For the Alpha release of this site, I will only be accepting email
            contributions. If you see an error, or notice an individual or
            organization that is missing, please email
            leatherapronchan@gmail.com with the details.
          </p>
          <br />

          <p>
            In the future, we will be creating a user account system, and a form
            which allows users to submit their own data on individuals. These
            submissions will be reviewed by Social Sentinel staff, and added to
            the database if they are quality, and the user will be awarded
            points on their account so we can see who the most committed
            contributors are. The newly created individuals page will also have
            that user's username displayed next to it so everyone will know they
            gathered that data.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default About;
