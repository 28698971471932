import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../app/api/axios";
import { Button, Input } from "@mui/material";
import {
  countryOptions,
  almaMaterOptions,
  completedStudies,
  ethnicityOptions,
  genderOptions,
  sexualityOptions,
  getBaseUrl,
  youTubeRegex,
  twitterRegex,
  instagramRegex,
  wikipediaRegex,
  tiktokRegex,
  individualPageLabels,
} from "../../helpers";
import Select from "react-select-virtualized";
import TextareaAutosize from "react-textarea-autosize";
import avatarPlaceholder from "../../res/avatar-placeholder.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  selectCurrentToken,
  selectCurrentUserInfo,
} from "../../features/auth/authSlice";
import { useSelector } from "react-redux";

import Tooltip from "@mui/material/Tooltip";

import InfoIcon from "@mui/icons-material/Info";
import SubmissionFormSearchBar from "../SubmissionFormSearchBar/SubmissionFormSearchBar";
import PersonalRelationsBuidler from "../PersonalRelationsBuidler/PersonalRelationsBuidler";
import SourcesBuilder from "../SourcesBuilder/SourcesBuilder";

const NATIONALITY_MAX = 2;
const ALMA_MATER_MAX = 3;

const INDIVIDUAL_URL = "/individual/";
const INDIVIDUAL_IMAGE_URL = "/individual_images/";

const IndividualEditForm = () => {
  const [emptyResponse, setEmptyResponse] = useState(false);

  const [nationality, setNationality] = useState([]);
  const [almaMater, setAlmaMater] = useState([]);
  const [studies, setStudies] = useState([]);
  const [image, setImage] = useState(null);
  const [dob, setDob] = useState(null);
  const [dateOfDeath, setDateOfDeath] = useState(null);

  const [individual, setIndividual] = useState(null);

  const token = useSelector(selectCurrentToken); // our current access token
  const userInfo = useSelector(selectCurrentUserInfo);

  const spouseRef = useRef();
  const relationsInputRef = useRef();
  const alsoKnownAsBuilderRef = useRef();
  const sourceBuilderRef = useRef();

  let { name } = useParams();

  useEffect(() => {
    const getIndividual = async () => {
      setEmptyResponse(false); // Needed so when the user navigates to an emtpy response page, then back to this one, the empty response message is not shown

      const { data } = await axios.get(INDIVIDUAL_URL + name);

      if (data.length === 0) {
        setEmptyResponse(true);
      }
      setIndividual(data[0]);

      // ======================= SET NATIONALITY =======================
      if (data[0].nationality !== null && data[0].nationality !== "") {
        let nationalityList = data[0].nationality.split("|");
        let nationalities = nationalityList.map((item) => {
          return { value: item, label: item };
        });
        setNationality(nationalities);
      }

      // ======================= SET ALMA MATER =======================
      if (data[0].alma_mater !== null && data[0].alma_mater !== "") {
        let almaMaterList = data[0].alma_mater.split("|");
        let almaMaters = almaMaterList.map((item) => {
          return { value: item, label: item };
        });
        setAlmaMater(almaMaters);
      }

      // ======================= SET STUDIES =======================
      if (data[0].tags !== null && data[0].tags !== "") {
        if (Array.from(data[0].tags)[0] === "|") {
          data[0].tags = data[0].tags.substring(1);
        }
        let studiesList = data[0].tags.split("|");
        let studies = studiesList.map((item) => {
          return { value: item, label: item };
        });
        setStudies(studies);
      }

      if (data[0].dob !== null) {
        setDob(new Date(data[0].dob));
      }
      if (data[0].date_of_death !== null) {
        setDateOfDeath(new Date(data[0].date_of_death));
      }
    };

    getIndividual();
  }, [name]);

  const getImageSrc = () => {
    if (!individual) {
      return avatarPlaceholder;
    } else if (individual.image_filename) {
      return getBaseUrl() + INDIVIDUAL_IMAGE_URL + individual.image_filename;
    } else {
      return individual.individual_image_url;
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();

    let error = false;
    let errorText = "";

    const formData = new FormData(e.target);
    const payload = Object.fromEntries(formData);

    let aliases = alsoKnownAsBuilderRef.current.getValues();
    // if any aliases are empty, don't submit and throw an error
    for (let i = 0; i < aliases.length; i++) {
      if (aliases[i] === "") {
        error = true;
        errorText =
          "Please fill out all alias fields or remove any empty alias fields.";
      }
    }

    let sources = sourceBuilderRef.current.getValues();
    for (let i = 0; i < sources.length; i++) {
      if (sources[i] === "") {
        error = true;
        errorText =
          "Please fill out all source fields or remove any empty source fields.";
      }
    }

    if (error) {
      toast.error(errorText, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      const fd = new FormData();

      // Needed to add parseInt because the payload values are strings

      let nativeNameString =
        payload.nativeName !== individual.native_name
          ? payload.nativeName
          : null;

      let biographyString =
        payload.biography !== individual.biography ? payload.biography : null;

      let ethnicityString =
        parseInt(payload.ethnicity) !== individual.ethnicity
          ? payload.ethnicity
          : null;

      let nationalityString = nationality.map((n) => n.value).join("|");
      let nationalityPayloadString =
        nationalityString !== individual.nationality ? nationalityString : null;

      let almaMaters = almaMater.map((n) => n.value).join("|");
      let almaMaterString =
        almaMaters !== individual.alma_mater ? almaMaters : null;

      let studiesString = studies.map((n) => n.value).join("|");
      let studiesPayloadString =
        studiesString !== individual.tags ? studiesString : null;

      let ethnicityNotesString =
        payload.ethnicityNotes !== individual.ethnicity_notes
          ? payload.ethnicityNotes
          : null;

      let wikipediaString =
        payload.wikipedia !== individual.wikipedia ? payload.wikipedia : null;

      let youtubeString =
        payload.youtube !== individual.youtube ? payload.youtube : null;

      let instagramString =
        payload.instagram !== individual.instagram ? payload.instagram : null;

      let tiktokString =
        payload.tiktok !== individual.tiktok ? payload.tiktok : null;

      let twitterString =
        payload.twitter !== individual.twitter ? payload.twitter : null;

      let personalSiteString = individual.personalSite
        ? payload.personalSite
        : null;

      // Check if any changes were made, and if not, do not send the request
      if (
        !(
          image ||
          nativeNameString ||
          biographyString ||
          ethnicityString ||
          nationalityPayloadString ||
          almaMaterString ||
          studiesPayloadString ||
          ethnicityNotesString ||
          wikipediaString ||
          youtubeString ||
          instagramString ||
          tiktokString ||
          twitterString ||
          personalSiteString
        )
      ) {
        toast.error("No changes detected. Please make a change before submitting your edit.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }

      // TODO: work on aliases and spouses. Need to check if changed, and I think need to load aliases correctly.
      // ALSO SOURCES NEED TO BE LOADED CORRECTLY

      fd.append("image", image);
      fd.append("name", individual.name); // Always send original name since it's the PK
      fd.append("nativeName", nativeNameString);
      fd.append("aliases", aliases.map((n) => n.value).join("|"));
      fd.append("biography", biographyString);
      fd.append("ethnicity", ethnicityString);
      fd.append("nationality", nationalityString);
      fd.append("almaMater", almaMaterString);
      fd.append("studies", studiesPayloadString);
      fd.append("spouse", spouseRef.current.getValues());
      fd.append("sources", sources.map((n) => n.value).join("|"));
      fd.append("ethnicityNotes", ethnicityNotesString);
      fd.append("wikipedia", wikipediaString);
      fd.append("youtube", youtubeString);
      fd.append("instagram", instagramString);
      fd.append("tiktok", tiktokString);
      fd.append("twitter", twitterString);
      fd.append("personalSite", personalSiteString);

      let dobPayload = null;
      if (payload.dob !== "") {
        let formDobString = new Date(payload.dob).toISOString().split("T")[0];
        let dobString = null;

        if (individual.dob !== null) {
          dobString = new Date(individual.dob).toISOString().split("T")[0];
        }

        if (formDobString !== dobString) {
          dobPayload = formDobString;
        }
      }
      fd.append("dob", dobPayload);

      let dateOfDeathPayload = null;
      if (payload.dateOfDeath !== "") {
        let formDateOfDeathString = new Date(payload.dateOfDeath)
          .toISOString()
          .split("T")[0];
        let dateOfDeathString = null;

        if (individual.date_of_death !== null) {
          dateOfDeathString = new Date(individual.date_of_death)
            .toISOString()
            .split("T")[0];
        }

        if (formDateOfDeathString !== dateOfDeathString) {
          dateOfDeathPayload = formDateOfDeathString;
        }
      }
      fd.append("dateOfDeath", dateOfDeathPayload);

      fd.append("isNewSubmission", 0);

      if (token) {
        const axiosConfig = {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };

        if (userInfo) {
          console.log(userInfo);
          fd.append("contributedBy", userInfo.telegram_id);
        }

        const { data } = await axios.post("/submitIndividual", fd, axiosConfig);

        console.log(data);
      } else {
        console.log("no token detected. Authenticate.");
      }
    }
  };

  const onChangeNationality = (value) => {
    let temp;
    if (Array.isArray(value)) {
      temp = value;
    } else {
      temp = [...nationality, value];
    }

    if (nationality.length >= NATIONALITY_MAX) {
      temp = temp.slice(1);
    }
    setNationality(temp);
  };

  const onChangeAlmaMater = (value) => {
    let temp;
    if (Array.isArray(value)) {
      temp = value;
    } else {
      temp = [...almaMater, value];
    }

    if (almaMater.length >= ALMA_MATER_MAX) {
      temp = temp.slice(1);
    }
    setAlmaMater(temp);
  };

  const onChangeStudies = (value) => {
    if (Array.isArray(value)) {
      setStudies(value);
    } else {
      setStudies([...studies, value]);
    }
  };

  return (
    <div className="main-section">
      {emptyResponse && <p>"{name}" not found</p>}
      {individual && (
        <form
          className="individuals-page"
          enctype="multipart/form-data"
          onSubmit={submitForm}
        >
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="individual-details">
            <div>
              <Input
                type="text"
                disabled
                placeholder="Enter Individual's Name"
                className="individual-submission-name-input"
                defaultValue={individual.name}
                required
                style={{
                  fontSize: "175%",
                  fontWeight: "bold",
                  fontFamily: "Quicksand",
                }}
                name="name"
              />
            </div>
            <hr />
            <div className="individual-detail-section">
              <div className="individual-submission-input-section">
                <label>
                  <Tooltip
                    className="tooltip"
                    placement="right-start"
                    title="This individual's birth name (not maiden name). Used if they go by a false name, or stage name (e.g. Lady Gaga = stage name, Caryn Johnson (AKA Whoopi Goldberg) = false name)."
                  >
                    <InfoIcon />
                  </Tooltip>
                  <b>{individualPageLabels.nativeName}</b>
                  <Input
                    type="text"
                    defaultValue={individual.native_name}
                    name="nativeName"
                  />
                </label>
              </div>

              <div className="individual-submission-input-section">
                <Tooltip
                  className="tooltip"
                  placement="right-start"
                  title="Other names this individual is known by. e.g. screen names, former aliases etc. This will make searching for this individual easier."
                >
                  <InfoIcon />
                </Tooltip>
                <b>{individualPageLabels.aliases}</b>
                <SourcesBuilder
                  ref={alsoKnownAsBuilderRef}
                  placeholderText="Alias"
                />
              </div>

              <div>
                <b>{individualPageLabels.biography}</b>
                <br />
                <TextareaAutosize
                  className="biography-textarea"
                  name="biography"
                  maxLength={50000}
                  placeholder="Write a brief bio here."
                  defaultValue={individual.biography}
                  minRows={4}
                  maxRows={10}
                />
              </div>

              <div>
                <b>{individualPageLabels.nationality} (MAX 2)</b>
                <Select
                  maxHeight={300}
                  isMulti
                  onChange={onChangeNationality}
                  value={nationality}
                  options={countryOptions}
                  name="nationality"
                />
              </div>

              <div>
                <b>{individualPageLabels.almaMater} (MAX 3)</b>{" "}
                <Select
                  maxHeight={300}
                  isMulti
                  onChange={onChangeAlmaMater}
                  value={almaMater}
                  options={almaMaterOptions}
                  name="almaMater"
                />
              </div>

              <div>
                <Tooltip
                  className="tooltip"
                  placement="right-start"
                  title="If this individual's spouse is already in the data set, select them here. Otherwise, simply type in their name."
                >
                  <InfoIcon />
                </Tooltip>
                <b>{individualPageLabels.spouse}</b>
                <SubmissionFormSearchBar
                  ref={spouseRef}
                  isIndividual={true}
                  defaultValue={{ name: individual.spouse }}
                />
              </div>
            </div>

            <div className="individual-detail-section">
              <div className="tooltip-div">
                <Tooltip
                  className="tooltip"
                  title="If this individual is a relevant part of an existing study, select it here and they will be added to the study's page."
                >
                  <InfoIcon />
                </Tooltip>
                <h1>{individualPageLabels.studies}</h1>
              </div>
              <hr />
              <div className="individual-detail-section">
                <Select
                  maxHeight={300}
                  onChange={onChangeStudies}
                  value={studies}
                  isMulti
                  options={completedStudies}
                  name="studies"
                />
              </div>
            </div>

            <div className="individual-detail-section">
              <h1>{individualPageLabels.relationsAndAssociates}</h1>
              <hr />
              <div className="individual-detail-section">
                <PersonalRelationsBuidler
                  name="relations"
                  ref={relationsInputRef}
                />
              </div>
            </div>
            <Button
              size="small"
              variant="contained"
              type="submit"
              className="individual-submit-button"
            >
              SUBMIT EDITS
            </Button>
          </div>

          <div className="spacer"></div>

          <div className="individual-image-container">
            <img
              className="individual-image"
              src={image ? URL.createObjectURL(image) : getImageSrc()}
              alt=""
            />
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg, image/webp"
              name="image"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
            />

            {image && (
              <Button
                className="individual-image-revert-button"
                variant="contained"
                size="small"
                onClick={() => setImage(null)}
              >
                {" "}
                Revert Image{" "}
              </Button>
            )}
            <div className="individual-image-details">
              <b>{individualPageLabels.gender}</b>
              <Select
                maxHeight={300}
                defaultValue={genderOptions[individual.lgbt]}
                options={genderOptions}
                name="gender"
              />
              <b>{individualPageLabels.sexuality}</b>
              <Select
                maxHeight={300}
                defaultValue={sexualityOptions[individual.lgbt]}
                options={sexualityOptions}
                name="sexuality"
              />
              <div>
                <b>{individualPageLabels.dob}</b>
              </div>
              <div>
                <DatePicker
                  defaultValue={individual.dob}
                  selected={dob}
                  onSelect={(date) => setDob(date)}
                  name="dob"
                  className="dob-datepicker"
                />
              </div>
              <Button
                size="small"
                variant="contained"
                onClick={() => setDob(individual.dob)}
                className="clear-date-button"
              >
                reset
              </Button>
              <div>
                <b>{individualPageLabels.dateOfDeath}</b>
              </div>
              <div>
                <div>
                  <DatePicker
                    defaultValue={individual.date_of_death}
                    selected={dateOfDeath}
                    onSelect={(date) => setDateOfDeath(date)}
                    name="dateOfDeath"
                    className="dob-datepicker"
                  />
                </div>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => setDateOfDeath(individual.date_of_death)}
                  className="clear-date-button"
                >
                  reset
                </Button>
              </div>
              <b>{individualPageLabels.ethnicity}</b>
              <Select
                required
                maxHeight={300}
                defaultValue={ethnicityOptions[individual.ethnicity - 1]}
                options={ethnicityOptions}
                name="ethnicity"
              />
              <div>
                <b>{individualPageLabels.ethnicityNotes}</b>
                <br />
                <TextareaAutosize
                  className="biography-textarea"
                  name="ethnicityNotes"
                  maxLength={50000}
                  placeholder="Details about the individual's ethnicity. e.g. Italian, Half-Jewish, etc."
                  defaultValue={individual.ethnicity_notes}
                  minRows={3}
                  maxRows={10}
                />
              </div>
              <div className="sources-div">
                <Tooltip
                  className="tooltip"
                  placement="right-start"
                  title="Sources which prove this person's ethnicity. HIGH QUALITY SOURCES ONLY. Nonsense and low quality sources may invalidate your submission."
                >
                  <InfoIcon />
                </Tooltip>
                <b>{individualPageLabels.sources}</b>
                <SourcesBuilder
                  ref={sourceBuilderRef}
                  placeholderText="Source"
                />
              </div>
              <div className="individual-detail-section">
                Wikipedia Link:
                <Input
                  type="text"
                  name="wikipedia"
                  defaultValue={individual.wikipedia}
                  inputProps={{
                    pattern: wikipediaRegex,
                  }}
                />
              </div>
              <div className="individual-detail-section">
                YouTube (or other video) Link:
                <Input
                  type="text"
                  defaultValue={individual.youtube}
                  name="youtube"
                  inputProps={{
                    pattern: youTubeRegex,
                  }}
                />
              </div>
              <div className="individual-detail-section">
                X (Twitter) Link:
                <Input
                  type="text"
                  name="twitter"
                  defaultValue={individual.twitter}
                  inputProps={{
                    pattern: twitterRegex,
                  }}
                />
              </div>
              <div className="individual-detail-section">
                Instagram Link:
                <Input
                  type="text"
                  name="instagram"
                  defaultValue={individual.instagram}
                  inputProps={{
                    pattern: instagramRegex,
                  }}
                />
              </div>
              <div className="individual-detail-section">
                TikTok Link:
                <Input
                  type="text"
                  name="tiktok"
                  defaultValue={individual.tiktok}
                  inputProps={{
                    pattern: tiktokRegex,
                  }}
                />
              </div>
              <div className="individual-detail-section">
                Personal Website Link:
                <Input type="text" name="personalSite" />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default IndividualEditForm;
