import avatarPlaceholder from "../../res/avatar-placeholder.png";
import star from "../../res/ethnicity_icons/jstar_circle_background.png";
import { Link } from "react-router-dom";
import { getIndividualUnderscored } from "../../helpers";
import ScaleText from "react-scale-text";

import "./memePortrait.css";

const MemePortrait = ({ individual }) => {
  const getIndividualImage = (src) => {
    if (src === "" || src === "0") {
      return avatarPlaceholder;
    } else {
      return src;
    }
  };

  const getEthnicityImage = (ethnicity) => {
    if (ethnicity === "Jewish") {
      return star;
    }
    // else if (ethnicity === "White") {
    //   return europe;
    // } else if (ethnicity === "Black") {
    //   return africa;
    // } else if (ethnicity === "East Asian") {
    //   return eastAsian;
    // } else if (ethnicity === "Hispanic") {
    //   return hispanic;
    // } else if (ethnicity === "MENA") {
    //   return mena;
    // } else if (ethnicity === "Other") {
    //   return other;
    // } else if (ethnicity === "South Asian") {
    //   return southAsian;
    // } else if (ethnicity === "Southeast Asian") {
    //   return southeastAsian;
    // } else if (ethnicity === "Mixed") {
    //   return mixed;
    // }
    // else {
    //   return unknown;
    // }
  };

  const stripName = (name) => {
    return name.replace(/ *\([^)]*\) */g, "");
  };

  return (
    individual && (
      <Link
        to={getIndividualUnderscored(individual.name)}
        target="_blank"
        rel="noreferrer"
      >
        <div className="meme-portrait-container">
          <img
            className="meme-portrait"
            src={getIndividualImage(individual.individual_image_url)}
            alt="img"
          />
          <img
            className="meme-ethnicity-img"
            src={getEthnicityImage(individual.ethnicity)}
            alt="eth"
          />
          <p className="meme-portrait-name-container">
            <ScaleText minFontSize={1} maxFontSize={18} widthOnly={true}>
              <b className="meme-portrait-name-text">{stripName(individual.name)}</b>
            </ScaleText>
          </p>
        </div>
      </Link>
    )
  );
};

export default MemePortrait;
