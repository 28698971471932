import { useState, useEffect } from "react";
import axios from "../../app/api/axios";
import {
  getOrganizationLogo,
  getOrganizationUnderscored,
} from "../../helpers";
import { useNavigate } from "react-router-dom";
import "./organizationSummary.css";

const ORGANIZATION_URL = "/organization/";

const OrganizationSummary = (props) => {
  const [organization, setOrganization] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(getOrganizationUnderscored(props.name));
  };

  useEffect(() => {
    const getOrganization = async () => {
      const { data } = await axios.get(ORGANIZATION_URL + props.name);
      setOrganization(data[0]);
    };
    getOrganization();
  }, [props.name]);

  const orgSummaryLogoClass = "organization-summary-logo";

  return (
    <div className="individual-summary" onClick={handleClick}>
      {!organization && (
        <img
          src="https://via.placeholder.com/300"
          className="individual-summary-image"
          alt="organization"
        />
      )}
      {organization && (
        <div>
          {getOrganizationLogo(organization.name, orgSummaryLogoClass)}
          <h2>{organization.abbrev_name}</h2>
          {organization.industry && (
            <p>
              <b>INDUSTRY:</b> {organization.industryString}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default OrganizationSummary;
